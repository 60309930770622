<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Proyectos | Modalidad Tradicional
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn
              color=""
              dark
              class="mb-2 float-right bg-light-blue"
              @click="modalNuevo"
            >
              Registrar nuevo
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="5" md="5" sm="6" xs="6">
            <v-text-field
              outlined
              autocomplete="off"
              dense
              v-model="filtro"
              label="Código / Nombre del proyecto"
              maxlength="100"
            ></v-text-field>
          </v-col>

          <v-col cols="5" md="5" sm="6" xs="6">
            <v-select
              v-model="estadoProyectoId"
              :items="estadosProyecto"
              :loading="false"
              dense
              outlined
              label="Estado"
              item-text="nombre"
              item-value="id"
              return-object
              :no-data-text="'No existen estados registrados'"
              menu-props="auto"
            ></v-select>
          </v-col>

          <v-col cols="2" md="2" sm="12" xs="6">
            <v-btn class="ma-0 white--text" medium color="light-blue darken-2">
              <v-icon left>mdi-magnify</v-icon> Buscar
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="proyectos"
          :search="filtro"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.codigo }}</td>
              <td>{{ item.fecha }}</td>
              <td>{{ item.nombre != null ? item.nombre : "" }}</td>

              <td>{{ item.institucion }}</td>
              <td>
                <v-chip
                  class="ma-2"
                  :color="
                    item.estadosId === 1
                      ? 'teal'
                      : item.estadosId === 2
                      ? 'blue'
                      : 'orange'
                  "
                  text-color="white"
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2"
                  small
                  tile
                  outlined
                  color="indigo"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>

                <v-btn class="ma-2" small tile outlined color="indigo">
                  <v-icon left>mdi-eye</v-icon> Ver detalles
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 1"
                  class="ma-2"
                  small
                  tile
                  outlined
                  color="indigo"
                  @click="mostrarDialogSuspension(item.nombre)"
                >
                  <v-icon left>mdi-av-timer</v-icon> POA
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 2"
                  class="ma-2"
                  small
                  tile
                  outlined
                  color="indigo"
                  @click="mostrarDialogSuspension(item.nombre)"
                >
                  <v-icon left>mdi-av-timer</v-icon> IAFF
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: dialog suspension -->
    <v-dialog v-model="dialogSuspension" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Suspensión del proyecto
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col sm="12">
              Está seguro de suspender el proyecto {{ proyectoSeleccionado }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <ModalRegistroProyectoSinOT ref="ModalRegistro" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

import validations from "@/core/untils/validations.js";

import ModalRegistroProyectoSinOT from "@/view/pages/proyectos-tradicionales/RegistroProyectosTradicionales.vue";

export default {
  name: "AdminProyectosTradicionales",
  data() {
    return {
      validForm: false,
      ddRolesLoading: false,
      switchItemEstado: true,
      dialogRegistro: false,

      dialogSuspension: false,
      proyectoSeleccionado: "",
      filtro: "",
      dialog: false,
      modalTitle: "Registrar nuevo proyecto",
      accion: 1,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyectoId: 0,
      estadosProyecto: [
        {
          id: 1,
          nombre: "Registrado"
        },
        {
          id: 2,
          nombre: "Seguimiento"
        },
        {
          id: 7,
          nombre: "Suspendido"
        }
      ],
      instituciones: [
        {
          id: 1,
          nombreInstitucion: "SEGEPLAN"
        },
        {
          id: 2,
          nombreInstitucion: "INSTITUCION 2"
        },
        {
          id: 3,
          nombreInstitucion: "INSTITUCION 3"
        }
      ],
      roles: [
        {
          id: 1,
          nombreRol: "Administrador"
        },
        {
          id: 2,
          nombreRol: "Rol2"
        },
        {
          id: 3,
          nombreRol: "Rol3"
        }
      ],
      datosUsuario: {
        nombre: "",
        correo: "",
        institucion: "",
        rolesId: 0,
        estado: 0
      },
      ...validations,
      proyectos: [
        {
          codigo: "TRAD-2022-0001",
          nombre: "Nombre del proyecto 1",
          correo: "correo1@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Finalizado",
          estadosId: 6,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0002",
          nombre: "Nombre del proyecto 2",
          correo: "correo2@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Suspendido",
          estadosId: 7,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0003",
          nombre: "Nombre del proyecto 3",
          correo: "correo3@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Registro",
          estadosId: 1,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0004",
          nombre: "Nombre del proyecto 4",
          correo: "correo4@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0005",
          nombre: "Nombre del proyecto 5",
          correo: "correo5@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0006",
          nombre: "Nombre del proyecto 6",
          correo: "correo6@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0007",
          nombre: "Nombre del proyecto 7",
          correo: "correo7@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0008",
          nombre: "Nombre del proyecto 8",
          correo: "correo7@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0009",
          nombre: "Nombre del proyecto 9",
          correo: "correo7@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        },
        {
          codigo: "TRAD-2022-0010",
          nombre: "Nombre del proyecto 10",
          correo: "correo7@mimail.com",
          institucion: "SEGEPLAN",
          rol: "Administrador",
          estado: "Seguimiento",
          estadosId: 2,
          fecha: "10/10/2021"
        }
      ],

      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "fecha"
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "nombre"
        },
        {
          text: "Institución Ejecutora",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ]
    };
  },
  components: {
    ModalRegistroProyectoSinOT
  },
  methods: {
    modalNuevo() {
      //this.dialogRegistro = true;
      this.$refs.ModalRegistro.mostrarModal();
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
      this.$refs.ModalRegistro.ocultarModal();
    },

    //Mostrar el dialogo para la suspension
    mostrarDialogSuspension(nombreProyecto) {
      this.proyectoSeleccionado = nombreProyecto;
      this.dialogSuspension = true;
    },

    resetForm() {},
    ///Registro de usuario
    registrarItem() {
      return "Listo";
    },
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Administración" },
      { title: "Proyectos" }
    ]);
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-sheet theme--light br-0",attrs:{"elevation":1}},[_c('v-card-title',[_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_vm._v(" Proyectos | Modalidad Tradicional ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{staticClass:"mb-2 float-right bg-light-blue",attrs:{"color":"","dark":""},on:{"click":_vm.modalNuevo}},[_vm._v(" Registrar nuevo ")])],1)],1),_c('v-row',{staticClass:"col-md-12 m-0 p-0"},[_c('v-divider')],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"5","md":"5","sm":"6","xs":"6"}},[_c('v-text-field',{attrs:{"outlined":"","autocomplete":"off","dense":"","label":"Código / Nombre del proyecto","maxlength":"100"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1),_c('v-col',{attrs:{"cols":"5","md":"5","sm":"6","xs":"6"}},[_c('v-select',{attrs:{"items":_vm.estadosProyecto,"loading":false,"dense":"","outlined":"","label":"Estado","item-text":"nombre","item-value":"id","return-object":"","no-data-text":'No existen estados registrados',"menu-props":"auto"},model:{value:(_vm.estadoProyectoId),callback:function ($$v) {_vm.estadoProyectoId=$$v},expression:"estadoProyectoId"}})],1),_c('v-col',{attrs:{"cols":"2","md":"2","sm":"12","xs":"6"}},[_c('v-btn',{staticClass:"ma-0 white--text",attrs:{"medium":"","color":"light-blue darken-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.proyectos,"search":_vm.filtro,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          'items-per-page-text': 'Registros por página',
          pageText: '{0}-{1} de {2}'
        }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.codigo))]),_c('td',[_vm._v(_vm._s(item.fecha))]),_c('td',[_vm._v(_vm._s(item.nombre != null ? item.nombre : ""))]),_c('td',[_vm._v(_vm._s(item.institucion))]),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.estadosId === 1
                    ? 'teal'
                    : item.estadosId === 2
                    ? 'blue'
                    : 'orange',"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[(item.estadosId === 1)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","tile":"","outlined":"","color":"indigo"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Actualizar ")],1):_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","tile":"","outlined":"","color":"indigo"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" Ver detalles ")],1),(item.estadosId === 1)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","tile":"","outlined":"","color":"indigo"},on:{"click":function($event){return _vm.mostrarDialogSuspension(item.nombre)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-av-timer")]),_vm._v(" POA ")],1):_vm._e(),(item.estadosId === 2)?_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","tile":"","outlined":"","color":"indigo"},on:{"click":function($event){return _vm.mostrarDialogSuspension(item.nombre)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-av-timer")]),_vm._v(" IAFF ")],1):_vm._e()],1)])]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogSuspension),callback:function ($$v) {_vm.dialogSuspension=$$v},expression:"dialogSuspension"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Suspensión del proyecto ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_vm._v(" Está seguro de suspender el proyecto "+_vm._s(_vm.proyectoSeleccionado)+" ")])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Continuar ")])],1)],1)],1),_c('ModalRegistroProyectoSinOT',{ref:"ModalRegistro"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }